import { ApolloLink } from 'apollo-link';
import { onError } from 'apollo-link-error';
import { createPersistedQueryLink } from 'apollo-link-persisted-queries';

import { AUTH_TOKEN, VUEX_STORE } from './constants/storage';
import { ERROR_CODES } from './constants/errors';
import { isValidToken } from './utils/validation';

export const removeData = () => {
  localStorage.removeItem(AUTH_TOKEN);
  localStorage.removeItem(VUEX_STORE);
};

const httpLink = new ApolloLink((operation, forward) => {
  // add the authorization to the headers
  const token = localStorage.getItem(AUTH_TOKEN);
  const headers = {};

  if (isValidToken(token)) headers.authorization = `Bearer ${token}`;

  operation.setContext({
    headers,
  });

  return forward(operation);
});

const errorLink = onError(({ networkError }) => {
  if (networkError && networkError.statusCode === ERROR_CODES.unauthorized) {
    removeData();
  }
});

export const authMiddleware = createPersistedQueryLink()
  .concat(errorLink)
  .concat(httpLink);
