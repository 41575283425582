<template>
  <v-dialog @click:outside="close" v-model="visible" max-width="600px">
    <form @submit.prevent>
      <v-card :loading="loading">
        <v-toolbar color="primary" dark flat>
          <v-toolbar-title class="headline">
            {{ $t(!item ? `create.title` : 'create.edit') }}
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col class="pb-0">
                <v-text-field
                  dense
                  v-model="editedPharmacy.pharmacyName"
                  :label="$t('create.pharmacyName')"
                  @blur="$v.editedPharmacy.pharmacyName.$touch()"
                  :error-messages="nameErrors"
                />
              </v-col>
              <v-col class="pb-0">
                <v-text-field
                  dense
                  v-model="editedPharmacy.address"
                  :label="$t('create.address')"
                  @blur="$v.editedPharmacy.address.$touch()"
                  :error-messages="addressErrors"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col class="pb-0">
                <v-text-field
                  dense
                  v-model="editedPharmacy.phone"
                  v-mask="masks.phone"
                  placeholder="000-000-0000"
                  :label="$t('create.phone')"
                  @blur="$v.editedPharmacy.phone.$touch()"
                  :error-messages="phoneErrors"
                />
              </v-col>
              <v-col class="pb-0">
                <v-text-field
                  dense
                  v-model="editedPharmacy.fax"
                  v-mask="masks.phone"
                  placeholder="000-000-0000"
                  :label="$t('create.fax')"
                  @blur="$v.editedPharmacy.fax.$touch()"
                  :error-messages="faxErrors"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn color="primary" text @click="close">
            {{ $t('create.cancel') }}
          </v-btn>
          <!-- Disabled if any fields are empty, or if phone/fax are not of form XXX-XXX-XXXX -->
          <v-btn
            @click="save"
            color="primary"
            :disabled="
              disabled ||
                !editedPharmacy.pharmacyName ||
                !editedPharmacy.address ||
                !(editedPharmacy.phone && $v.editedPharmacy.phone.minLength) ||
                !(editedPharmacy.fax && $v.editedPharmacy.fax.minLength)
            "
          >
            {{ $t('create.save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </form>
  </v-dialog>
</template>

<script>
import to from 'await-to-js';
import { required, minLength } from 'vuelidate/lib/validators';
import { getError } from '../utils/graphql';
import { deepClone } from '../utils/validation';
import * as masks from '../constants/masks';
import { VALIDATION_ERRORS } from '../constants/validation';
import ADD_PHARMACY from '../graphql/Mutation/addPharmacy.gql';
import UPDATE_PHARMACY from '../graphql/Mutation/updatePharmacy.gql';

const defaultPharmacy = {
  pharmacyName: '',
  address: '',
  phone: '',
  fax: '',
  id: '',
};

export default {
  name: 'PharmacyEditor',
  data() {
    return {
      editedPharmacy: deepClone(defaultPharmacy),
      disabled: false,
      loading: false,
      masks,
    };
  },
  props: {
    visible: Boolean,
    item: Object,
  },
  watch: {
    item: {
      immediate: true,
      deep: true,
      handler() {
        this.editedPharmacy = deepClone(defaultPharmacy);
        if (this.item) {
          this.editedPharmacy.pharmacyName = this.item.name;
          this.editedPharmacy.address = this.item.address;
          this.editedPharmacy.phone = this.item.phone1;
          this.editedPharmacy.fax = this.item.fax;
          this.editedPharmacy.id = this.item.id;
        }
      },
    },
  },
  methods: {
    close() {
      if (!this.item) {
        this.editedPharmacy = deepClone(defaultPharmacy);
      }
      this.$v.editedPharmacy.$reset();
      this.$emit('close');
    },
    async save() {
      this.loading = true;
      this.disabled = true;
      let errors;

      const newPharmacy = {
        name: this.editedPharmacy.pharmacyName,
        address: this.editedPharmacy.address,
        phone1: this.editedPharmacy.phone,
        fax: this.editedPharmacy.fax,
      };

      if (this.item) {
        [errors] = await to(
          this.$apollo.mutate({
            mutation: UPDATE_PHARMACY,
            variables: {
              pharmacyId: this.editedPharmacy.id,
              updates: { ...newPharmacy },
            },
          })
        );
      } else {
        [errors] = await to(
          this.$apollo.mutate({
            mutation: ADD_PHARMACY,
            variables: { ...newPharmacy },
          })
        );
      }

      const message = this.$t(this.item ? 'create.updateSuccess' : 'create.success');

      this.$store.dispatch('setNotification', {
        color: errors ? 'error' : 'success',
        text: errors ? getError(errors) : message,
        timeout: 5000,
      });

      if (!errors) {
        this.$emit('refresh');
      }

      this.loading = false;
      this.disabled = false;
      this.close();
    },
  },
  computed: {
    nameErrors() {
      const errors = [];
      if (!this.$v.editedPharmacy.pharmacyName.$dirty) return errors;
      if (!this.$v.editedPharmacy.pharmacyName.required) {
        errors.push(VALIDATION_ERRORS.REQUIRED);
      }
      return errors;
    },
    addressErrors() {
      const errors = [];
      if (!this.$v.editedPharmacy.address.$dirty) return errors;
      if (!this.$v.editedPharmacy.address.required) {
        errors.push(VALIDATION_ERRORS.REQUIRED);
      }
      return errors;
    },
    phoneErrors() {
      const errors = [];
      if (!this.$v.editedPharmacy.phone.$dirty) return errors;
      if (!this.$v.editedPharmacy.phone.required) {
        errors.push(VALIDATION_ERRORS.REQUIRED);
      }
      if (!this.$v.editedPharmacy.phone.minLength) {
        errors.push(VALIDATION_ERRORS.PHONE_MIN_LENGTH);
      }
      return errors;
    },
    faxErrors() {
      const errors = [];
      if (!this.$v.editedPharmacy.fax.$dirty) return errors;
      if (!this.$v.editedPharmacy.fax.required) {
        errors.push(VALIDATION_ERRORS.REQUIRED);
      }
      if (!this.$v.editedPharmacy.fax.minLength) {
        errors.push(VALIDATION_ERRORS.FAX_MIN_LENGTH);
      }
      return errors;
    },
  },
  validations: {
    editedPharmacy: {
      pharmacyName: {
        required,
      },
      address: {
        required,
      },
      phone: {
        required,
        minLength: minLength(12),
      },
      fax: {
        required,
        minLength: minLength(12),
      },
    },
  },
};
</script>
