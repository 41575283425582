<template>
  <v-dialog :value="value" max-width="400px" persistent @input="$emit('input', $event)">
    <v-card>
      <v-card-title class="headline">
        {{ $t('deleteConfirmation.title') }}
      </v-card-title>
      <v-card-text>
        {{ $t('deleteConfirmation.text') }}
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn color="primary" text @click="$emit('input', false)">
          {{ $t('create.cancel') }}
        </v-btn>
        <v-btn color="error" @click="$emit('confirmation')">
          {{ $t('deleteConfirmation.delete') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'DeleteConfirmation',
  props: {
    value: Boolean,
  },
};
</script>
