<template>
  <div class="application-wrapper d-flex align-center justify-center">
    <v-form class="ma-auto login error-container" @submit.prevent="login" :disabled="disabled">
      <v-card class="pb-12 mx-auto text-center" width="550" outlined :loading="loading">
        <v-card-text class="error-text mt-12">
          <h1 class="pb-12" text-capitalize>{{ $t('main.loginTitle') }}</h1>
          <v-text-field
            class="mx-16 pl-8 login-field"
            :error="errorStatus"
            :label="$t('input.email')"
            type="email"
            v-model="email"
            outlined
          ></v-text-field>
          <v-text-field
            class="mx-16 pl-8 login-field"
            :error-messages="errorMessage"
            :label="$t('input.password')"
            type="password"
            v-model="password"
            outlined
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue white--text"
            :disabled="!email || !password || disabled"
            elevation="4"
            type="submit"
            contained
          >
            {{ $t('login.submit') }}
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-form>
  </div>
</template>

<style>
.login-field {
  width: 68%;
}
</style>

<script>
import to from 'await-to-js';
import LOGIN from '../graphql/Mutation/Login.gql';
import GET_PRIMARY_ROLE from '../graphql/Query/GetPrimaryRole.gql';
import { AUTH_TOKEN } from '../constants/storage';
import { MEMBER } from '../constants/roles';

export default {
  name: 'Login',
  data() {
    return {
      email: '',
      password: '',
      errorStatus: false,
      errorMessage: null,
      disabled: false,
      loading: false,
    };
  },
  methods: {
    async login() {
      this.disabled = true;
      this.loading = true;

      const { tenantUrl } = this.$route.params;

      const [errors, query] = await to(
        this.$apollo.mutate({
          mutation: LOGIN,
          variables: {
            email: this.email,
            password: this.password,
            tenantUrl: tenantUrl || null,
          },
        })
      );

      if (errors || query.errors) {
        this.disabled = false;
        this.errorStatus = true;
        this.errorMessage = this.$t('errors.validationError');
        this.loading = false;

        return;
      }

      const token = query.data.login;

      if (await this.checkMember(token)) return;

      localStorage.setItem(AUTH_TOKEN, token);
      await this.$store.dispatch('getAndSaveUser');
      this.$store.commit('saveUser', { tenantUrl: tenantUrl || '' });
      this.$router.push({ name: 'Home' });
    },
    async checkMember(token) {
      const [getPrimaryRoleErrors, getPrimaryRoleQuery] = await to(
        this.$apollo.query({
          query: GET_PRIMARY_ROLE,
          variables: {
            token,
          },
        })
      );

      if (
        getPrimaryRoleErrors ||
        getPrimaryRoleQuery.errors ||
        getPrimaryRoleQuery.data.getPrimaryRole === MEMBER
      ) {
        this.disabled = false;
        this.errorStatus = true;
        this.errorMessage = this.$t('errors.validationError');
        this.loading = false;

        return true;
      }

      return false;
    },
  },
  computed: {
    userInfo() {
      return this.$store.state.user.info;
    },
  },
  mounted() {
    if (this.userInfo.id) {
      this.$router.push({ name: 'Home' }); // redirect to homepage if already logged in
    }
  },
};
</script>
