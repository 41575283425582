import createLogger from 'vuex/dist/logger';
import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersist from 'vuex-persist';

import to from 'await-to-js';
import { NODE_ENV } from '../constants/env';
import { VUEX_STORE, EXCLUDE_KEYS } from '../constants/storage';
import { apolloClient } from '../vue-apollo';
import GET_CURRENT_USER from '../graphql/Query/GetCurrentUser.gql';
import GET_PHARMACIES from '../graphql/Query/GetAllPharmacies.gql';

Vue.use(Vuex);

const vuexLocalStorage = new VuexPersist({
  key: VUEX_STORE,
  storage: window.localStorage,
  reducer: (state) => {
    const copy = { ...state };

    EXCLUDE_KEYS.forEach((k) => {
      delete copy[k];
    });

    return copy;
  },
});

const defaultNotification = {
  color: '',
  showing: false,
  text: '',
  timeout: -1,
};

const defaultUser = {
  info: {
    tenantUrl: '',
  },
};

const defaultState = function() {
  return {
    user: {
      info: {
        tenantUrl: '',
      },
    },
    pharmacies: [],
  };
};

const store = new Vuex.Store({
  state: {
    notification: { ...defaultNotification },
    user: { ...defaultUser },
    pharmacies: [],
  },
  getters: {
    isLoggedIn(state) {
      // not even sure if this is needed rn
      return Boolean(state.user.info.id);
    },
  },
  mutations: {
    set(state, updates) {
      Object.assign(state, updates);
    },
    saveUser(state, value) {
      state.user.info = { ...state.user.info, ...value };
    },
    clearData(state) {
      Object.assign(state, defaultState());
    },
    clearPharmacies(state) {
      state.pharmacies = [];
    },
  },
  actions: {
    setNotification({ commit }, notification) {
      commit('set', {
        notification: {
          ...defaultNotification,
          ...notification,
          showing: true,
        },
      });
    },
    async getAndSaveUser({ commit }) {
      const { data } = await apolloClient.query({
        query: GET_CURRENT_USER,
        fetchPolicy: 'no-cache',
      });

      const info = { ...data.getCurrentUser };

      commit('saveUser', info);
    },
    async getPharmacies({ state }, prefix) {
      const [errors, query] = await to(
        apolloClient.query({
          query: GET_PHARMACIES,
          variables: {
            prefix,
          },
          fetchPolicy: 'no-cache',
        })
      );

      if (errors) {
        return;
      }
      const { getAllPharmacies: pharmacies = [] } = query.data;
      state.pharmacies = pharmacies;
    },
  },
  plugins: [vuexLocalStorage.plugin, ...(NODE_ENV === 'development' ? [createLogger()] : [])],
});

export default store;
