import i18n from '../plugins/i18n';

// eslint-disable-next-line import/prefer-default-export
export const VALIDATION_ERRORS = {
  REQUIRED: i18n.t('validation.required'),
  PHONE_MIN_LENGTH: i18n.t('validation.phoneMinLength'),
  FAX_MIN_LENGTH: i18n.t('validation.faxMinLength'),
};

export const EMPTY_VALUES = ['', null, undefined];
