<template>
  <v-container>
    <v-toolbar elevation="0">
      <v-spacer />
      <v-btn dark color="blue" @click="create">
        <v-icon> mdi-plus </v-icon>
        {{ $t('database.new') }}
      </v-btn>
    </v-toolbar>
    <pharmacy-editor
      :item="editedPharmacy"
      :visible="isDialogOpen"
      @close="close"
      @refresh="refresh"
    ></pharmacy-editor>
    <v-card>
      <v-card-title>
        <v-spacer />
        <v-text-field v-model="search" :label="$t('database.search')" single-line hide-details />
        <v-btn
          class="ml-3 white--text"
          color="blue"
          :disabled="disabled || !search"
          @click="getPharmacies"
        >
          <v-icon> mdi-magnify </v-icon>
        </v-btn>
      </v-card-title>
      <v-data-table
        :loading="loading"
        :headers="headers"
        :options.sync="tableOptions"
        :items="pharmacies"
      >
        <template v-slot:[`item.id`]="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" @click="copyId(item.id)" icon>
                <v-icon>mdi-content-copy</v-icon>
              </v-btn>
            </template>
            <span>{{ $t('database.copyId') }}</span>
          </v-tooltip>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" @click="editItem(item)" icon>
                <v-icon>mdi-pencil-outline</v-icon>
              </v-btn>
            </template>
            <span>{{ $t('database.edit') }}</span>
          </v-tooltip>

          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" @click="deleteItem(item)" icon>
                <v-icon>mdi-delete-outline</v-icon>
              </v-btn>
            </template>
            <span>{{ $t('database.delete') }}</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card>

    <delete-confirmation @confirmation="deletePharmacy" v-model="isDeleteOpen">
    </delete-confirmation>
  </v-container>
</template>

<script>
import to from 'await-to-js';
import PharmacyEditor from '../components/PharmacyEditor.vue';
import DeleteConfirmation from '../components/DeleteConfirmation.vue';
import REMOVE_PHARMACY from '../graphql/Mutation/removePharmacy.gql';

export default {
  name: 'Home',
  components: {
    PharmacyEditor,
    DeleteConfirmation,
  },
  data() {
    return {
      headers: [
        { text: this.$t('database.id'), value: 'id', sortable: false, align: 'center' },
        { text: this.$t('database.name'), value: 'name', align: 'center' },
        { text: this.$t('database.address'), value: 'address', align: 'center' },
        { text: this.$t('database.phone'), value: 'phone1', align: 'center' },
        { text: this.$t('database.fax'), value: 'fax', align: 'center' },
        { text: this.$t('database.actions'), value: 'actions', sortable: false, align: 'center' },
      ],
      isDialogOpen: false,
      loading: false,
      disabled: false,
      search: '',
      prevSearch: '',
      editedPharmacy: null,
      isDeleteOpen: false,
      pharmacyForDeletion: null,
      tableOptions: {
        page: 1,
        itemsPerPage: 10,
        sortBy: ['name'],
        sortAsc: [true],
        mustSort: true,
      },
    };
  },
  methods: {
    async getPharmacies() {
      if (this.search === this.prevSearch || !this.search) {
        return;
      }
      this.disabled = true;
      this.loading = true;

      await this.$store.dispatch('getPharmacies', this.search);
      this.tableOptions.page = 1;
      this.prevSearch = this.search;

      this.disabled = false;
      this.loading = false;
    },
    async refresh() {
      if (!this.prevSearch) {
        return;
      }

      this.disabled = true;
      this.loading = true;

      await this.$store.dispatch('getPharmacies', this.search);
      this.tableOptions.page = 1;
      this.prevSearch = this.search;

      this.disabled = false;
      this.loading = false;
    },
    async deletePharmacy() {
      this.loading = true;
      this.disabled = true;

      const { id } = this.pharmacyForDeletion;

      const [error] = await to(
        this.$apollo.mutate({
          mutation: REMOVE_PHARMACY,
          variables: {
            pharmacyId: id,
          },
        })
      );

      if (!error) {
        this.$store.commit('set', {
          pharmacies: this.pharmacies.filter((pharmacy) => pharmacy.id !== id),
        });

        this.isDeleteOpen = false;
        this.refresh();
      }

      this.$store.dispatch('setNotification', {
        color: error ? 'error' : 'success',
        text: this.$t(error ? 'errors.delete' : 'deleteConfirmation.success'),
        timeout: 5000,
      });

      this.loading = false;
      this.disabled = false;
    },
    create() {
      this.editedPharmacy = null;
      this.isDialogOpen = true;
    },
    close() {
      this.isDialogOpen = false;
    },
    editItem(item) {
      this.editedPharmacy = item;
      this.isDialogOpen = true;
    },
    deleteItem(item) {
      this.isDeleteOpen = true;
      this.pharmacyForDeletion = item;
    },
    copyId(id) {
      navigator.clipboard.writeText(id);
    },
  },
  computed: {
    userInfo() {
      return this.$store.state.user.info;
    },
    pharmacies() {
      return this.$store.state.pharmacies;
    },
  },
  watch: {
    isDeleteOpen(value) {
      if (!value) {
        this.pharmacyForDeletion = null;
      }
    },
  },
  mounted() {
    // temporary solution to redirect to login if a login has not yet been done
    if (!this.userInfo.id) {
      this.$router.push({ name: 'Login', params: { tenantUrl: 'demo' } }); // currently set to prefer demo tenant
    }

    this.$store.commit('clearPharmacies');
  },
};
</script>
