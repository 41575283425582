import i18n from '../plugins/i18n';

// eslint-disable-next-line import/prefer-default-export
export const getError = (errors, message = null) => {
  if (message) {
    return message;
  }
  const { code } = errors?.networkError?.result?.errors[0]?.extensions;
  const { statusCode } = errors.networkError;

  const codeErrors = {
    INTERNAL_SERVER_ERROR: null,
    GRAPHQL_PARSE_FAILED: null,
    GRAPHQL_VALIDATION_FAILED: null,
    BAD_USER_INPUT: null,
    PERSISTED_QUERY_NOT_FOUND: null,
    PERSISTED_QUERY_NOT_SUPPORTED: null,
    UNAUTHENTICATED: i18n.t('errors.unauthenticated'),
    FORBIDDEN: i18n.t('errors.forbidden'),
  };

  if (statusCode === 429) {
    return i18n.t('errors.tooManyRequests');
  }
  return codeErrors[code] || null;
};
