import { createApolloClient, restartWebsockets } from 'vue-cli-plugin-apollo/graphql-client';
import Vue from 'vue';
import VueApollo from 'vue-apollo';

import { authMiddleware, removeData } from './auth';
import { GATEWAY_HTTP } from './constants/env';

// Install the vue plugin
Vue.use(VueApollo);

// Name of the localStorage item
const AUTH_TOKEN = 'apollo-token';

// Http endpoint
const httpEndpoint = `${GATEWAY_HTTP}/graphql`;
// Files URL root
export const filesRoot = httpEndpoint.substr(0, httpEndpoint.indexOf('/graphql'));

Vue.prototype.$filesRoot = filesRoot;

// Config
const defaultOptions = {
  httpEndpoint,
  link: authMiddleware,
  persisting: false,
  ssr: false,
  tokenName: AUTH_TOKEN,
  websocketsOnly: false,
  wsEndpoint: null,
};

const apolloClientService = createApolloClient(defaultOptions);
export const { apolloClient } = apolloClientService;

// Call this in the Vue app file
export function createProvider() {
  // Create apollo client
  apolloClient.wsClient = apolloClientService.wsClient;

  // Create vue apollo provider
  const apolloProvider = new VueApollo({
    defaultClient: apolloClient,
    defaultOptions: {
      $query: {
        fetchPolicy: 'cache-and-network',
        errorPolicy: 'all',
      },
      $mutate: {
        errorPolicy: 'all',
      },
    },
  });

  return apolloProvider;
}

// Manually call this when user log in
export async function onLogin(token) {
  localStorage.setItem(AUTH_TOKEN, token);
  if (apolloClient.wsClient) restartWebsockets(apolloClient.wsClient);
  try {
    await apolloClient.resetStore();
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log('%cError on cache reset (login)', 'color: orange;', e.message);
  }
}

// Manually call this when user log out
export async function onLogout() {
  removeData();

  if (apolloClient.wsClient) restartWebsockets(apolloClient.wsClient);
  await apolloClient.resetStore();
}
