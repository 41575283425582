/* import { LANGUAGE } from '../constants/storage';
import {
  DATE_FORMAT,
  INPUT_DATE_FORMAT,
  PRETTY_DATE_FORMAT,
  PRETTY_SMALL_DATE_FORMAT,
  TIME_FORMAT,
} from '../constants/moment';
import store from '../store'; */

const en = {
  main: {
    title: 'Pharmacy Database',
    loginTitle: 'Pharmacy Login',
  },
  database: {
    new: 'New',
    search: 'Search Pharmacy Names (Case Sensitive)',
    copyId: 'Copy pharmacy ID to clipboard',
    id: 'ID',
    name: 'Name',
    address: 'Address',
    phone: 'Phone',
    fax: 'Fax',
    actions: 'Actions',
    edit: 'Edit',
    delete: 'Delete',
  },
  create: {
    title: 'Create Pharmacy',
    edit: 'Edit Pharmacy',
    pharmacyName: 'Pharmacy Name',
    address: 'Street Number and Name',
    phone: 'Phone Number',
    fax: 'Fax Number',
    cancel: 'Cancel',
    save: 'Save',
    success: 'Pharmacy successfully created',
    updateSuccess: 'Pharmacy successfully updated',
  },
  deleteConfirmation: {
    title: 'Delete Confirmation',
    text: 'Are you sure you want to delete this pharmacy?',
    delete: 'Delete',
    success: 'Pharmacy was successfully deleted.',
  },
  validation: {
    required: 'Field is required',
    phoneMinLength: 'Phone number is not long enough',
    faxMinLength: 'Fax number is not long enough',
  },
  input: {
    email: 'E-mail',
    password: 'Password',
  },
  login: {
    submit: 'Login',
  },
  errors: {
    validationError: 'Incorrect user or password, try again.',
    tooManyRequests: 'Too many requests. Please try again in a few seconds.',
    unauthenticated: 'Unable to authenticate your account. Please login again.',
    forbidden: 'Your account does not have access to this resource.',
    delete: 'Pharmacy delete was unsuccessful.',
  },
};

export default en;
