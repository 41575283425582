var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-toolbar',{attrs:{"elevation":"0"}},[_c('v-spacer'),_c('v-btn',{attrs:{"dark":"","color":"blue"},on:{"click":_vm.create}},[_c('v-icon',[_vm._v(" mdi-plus ")]),_vm._v(" "+_vm._s(_vm.$t('database.new'))+" ")],1)],1),_c('pharmacy-editor',{attrs:{"item":_vm.editedPharmacy,"visible":_vm.isDialogOpen},on:{"close":_vm.close,"refresh":_vm.refresh}}),_c('v-card',[_c('v-card-title',[_c('v-spacer'),_c('v-text-field',{attrs:{"label":_vm.$t('database.search'),"single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-btn',{staticClass:"ml-3 white--text",attrs:{"color":"blue","disabled":_vm.disabled || !_vm.search},on:{"click":_vm.getPharmacies}},[_c('v-icon',[_vm._v(" mdi-magnify ")])],1)],1),_c('v-data-table',{attrs:{"loading":_vm.loading,"headers":_vm.headers,"options":_vm.tableOptions,"items":_vm.pharmacies},on:{"update:options":function($event){_vm.tableOptions=$event}},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.copyId(item.id)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-content-copy")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('database.copyId')))])])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.editItem(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-pencil-outline")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('database.edit')))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.deleteItem(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-delete-outline")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('database.delete')))])])]}}],null,true)})],1),_c('delete-confirmation',{on:{"confirmation":_vm.deletePharmacy},model:{value:(_vm.isDeleteOpen),callback:function ($$v) {_vm.isDeleteOpen=$$v},expression:"isDeleteOpen"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }