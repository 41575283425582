import Fragment from 'vue-fragment';
import Vue from 'vue';
import Vuelidate from 'vuelidate';
import VueMask from 'v-mask';

import { createProvider } from './vue-apollo';
import App from './App.vue';
import i18n from './plugins/i18n';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';

Vue.use(Fragment.Plugin);
Vue.use(Vuelidate);
Vue.use(VueMask);

Vue.config.productionTip = false;

new Vue({
  apolloProvider: createProvider(),
  render: (h) => h(App),
  router,
  store,
  i18n,
  vuetify,
}).$mount('#app');
