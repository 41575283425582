<template>
  <v-app-bar app clipped-left color="primary" dark v-if="user.id">
    <v-toolbar-title>
      {{ $t('main.title') }}
    </v-toolbar-title>

    <v-spacer />

    <v-btn @click="logout" icon color="white">
      <v-icon>mdi-logout</v-icon>
    </v-btn>
  </v-app-bar>
</template>

<script>
import { onLogout } from '../vue-apollo';

export default {
  name: 'NavBar',
  methods: {
    async logout() {
      const { tenantUrl } = this.user;

      this.$store.commit('clearData');
      await onLogout();

      this.$router.push({ name: 'Login', params: { tenantUrl } });
    },
  },
  computed: {
    user() {
      return this.$store.state.user.info;
    },
  },
};
</script>
