<template>
  <v-app>
    <nav-bar></nav-bar>
    <notification-snack></notification-snack>
    <v-main>
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<style>
main {
  overflow: hidden;
}

body,
.v-application {
  font-family: 'Montserrat', sans-serif !important;
}
.application-wrapper {
  height: 100%;
  width: 100%;
}
</style>

<script>
import NotificationSnack from './components/NotificationSnack.vue';
import NavBar from './components/NavBar.vue';

export default {
  name: 'App',
  components: { NotificationSnack, NavBar },
  data: () => ({}),
};
</script>
